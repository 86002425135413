import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
// import Img from "../../components/image-transform"
import MailTo from "../../components/mail-to"

const content = {
  title: "We're not actively hiring at the moment",
  content: (
    <>
      <p className="section-text">
        But you can always email us at{" "}
        {MailTo({classes: ''})} with a
        speculative CV. After all, maybe you're the essential person that we
        never knew we needed. Do keep checking back, because we'll update this
        page when we're looking for someone new.
      </p>
    </>
  ),
}

const banner = {
  title: "There's always room for one more on the sofa",
  image: require("../../images/team-on-sofa.svg"),
  alt: "Billsby",
}

const Career = () => {
  return (
    <Layout>
      <SEO
        title="Careers"
        description="Join Billsby and work to make it easier for companies around the world to handle recurring subscription payments"
        url="https://www.billsby.com/company/careers"
      />
      <div className="career">
        <div className="section-hero">
          <h1 className="section-heading">{banner.title}</h1>
          <img src={banner.image} alt={banner.alt} />
        </div>

        <div className="section-career">
          <div className="container">
            <h2 className="section-heading">{content.title}</h2>
            {content.content}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Career
